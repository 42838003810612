import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { dateTimeFromNgbDate } from 'src/app/common/utilities/date-helpers';
import {
  AddPdTranscriptEntryPayload,
  CreatePdSessionPayload,
  GetPdTranscriptsPayload,
  PdListFilterDTO,
  PdListFilterPayload,
} from '../../dtos/pd.dto';

@Injectable({
  providedIn: 'root',
})
export class PdService {
  constructor(private apiService: APICoreService) {}

  registerSession(sessionId: number, userId: number) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/register`,
      {
        user_id: userId,
      }
    );
  }

  unregisterSession(sessionId: number, userId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/${sessionId}/attendee/${userId}`
    );
  }

  getSessions(filters?: PdListFilterDTO) {
    if (filters) {
      const payload: PdListFilterPayload = {
        from_date: filters.from_date
          ? dateTimeFromNgbDate(filters.from_date).toSeconds()
          : null,
        to_date: filters.to_date
          ? dateTimeFromNgbDate(filters.to_date)
              .set({ hour: 23, minute: 59, second: 59 })
              .toSeconds()
          : null,
        search: filters.keyword,
        facilitators: filters.facilitators
          ? filters.facilitators?.map((facilitator) => facilitator.id)
          : [],
        types: filters.types,
        is_virtual: filters.virtual ? 1 : 0,
        onsite: filters.onsite ? 1 : 0,
        categories: filters.categories,
        per_page: filters.per_page,
        page: filters.page,
        include_completed: filters.include_completed ? 1 : 0,
      };
      return this.apiService.getRequest('growelab/pd/sessions', payload);
    }
    return this.apiService.getRequest('growelab/pd/sessions');
  }

  getTranscript(userId: number, payload: GetPdTranscriptsPayload) {
    return this.apiService.getRequest(
      `growelab/pd/user/${userId}/transcript`,
      payload
    );
  }

  getTranscriptYearlyTotals(userId: number, schoolYear: string) {
    return this.apiService.getRequest(
      `growelab/pd/user/${userId}/transcript-year-totals?school_year=${schoolYear}`
    );
  }

  addTranscriptEntry(userId: number, payload: AddPdTranscriptEntryPayload) {
    return this.apiService.postRequest(
      `growelab/pd/user/${userId}/transcript`,
      payload
    );
  }

  getAdminSettings(district_id = 0) {
    return this.apiService.getRequest(
      `growelab/pd/admin-settings?district_id=${district_id}`
    );
  }

  addCategory(payload: { district_id: number; title: string }) {
    return this.apiService.postRequest('growelab/pd/admin/category', payload);
  }

  addCategoryOption(
    categoryId: number,
    payload: { district_id: number; title: string }
  ) {
    return this.apiService.postRequest(
      `growelab/pd/admin/category/${categoryId}/option`,
      payload
    );
  }

  deleteCategory(payload: { district_id: number; categoryId: number }) {
    return this.apiService.deleteRequest(
      `growelab/pd/admin/category/${payload.categoryId}?district_id=${payload.district_id}`
    );
  }

  deleteCategoryOption(payload: { district_id: number; optionId: number }) {
    return this.apiService.deleteRequest(
      `growelab/pd/admin/category/option/${payload.optionId}?district_id=${payload.district_id}`
    );
  }

  addCredit(payload: { district_id: number; title: string }) {
    return this.apiService.postRequest('growelab/pd/admin/credit', payload);
  }

  deleteCredit(payload: { district_id: number; creditId: number }) {
    return this.apiService.deleteRequest(
      `growelab/pd/admin/credit/${payload.creditId}?district_id=${payload.district_id}`
    );
  }

  downloadTranscript(userId: number) {
    return this.apiService.getRequest(
      `growelab/pd/user/${userId}/transcript/download`
    );
  }

  deleteTranscriptEntry(entryId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/transcript/item/${entryId}`
    );
  }

  createSession(payload: CreatePdSessionPayload) {
    return this.apiService.postJSONRequest('growelab/pd/session', payload).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }
}
