import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PdCategory, PdCategoryOption } from '../../../dtos/pd.dto';
import { PdService } from '../../../services/pd/pd.service';

@Component({
  selector: 'app-pd-settings-category',
  templateUrl: './pd-settings-category.component.html',
  styleUrl: './pd-settings-category.component.scss',
})
export class PdSettingsCategoryComponent {
  @Input() category: PdCategory;

  @Input() districtId: number;

  @Output() readonly categoryUpdated = new EventEmitter();

  @ViewChild('addOptionInput') addOptionInput: ElementRef;

  addOptionValue: string;

  addOptionMode = false;

  constructor(private pdService: PdService) {}

  addOption() {
    this.pdService
      .addCategoryOption(this.category.id, {
        district_id: this.districtId,
        title: this.addOptionValue,
      })
      .subscribe((res) => {
        if (res) this.categoryUpdated.emit();
      });
  }

  deleteOption(option: PdCategoryOption) {
    this.pdService
      .deleteCategoryOption({
        district_id: this.districtId,
        optionId: option.id,
      })
      .subscribe((res) => {
        if (res) this.categoryUpdated.emit();
      });
  }

  deleteCategory() {
    this.pdService
      .deleteCategory({
        district_id: this.districtId,
        categoryId: this.category.id,
      })
      .subscribe((res) => {
        if (res) this.categoryUpdated.emit();
      });
  }

  turnOnAddOptionMode() {
    this.addOptionMode = true;
    setTimeout(() => {
      this.addOptionInput.nativeElement.focus();
    });
  }
}
