import { GLEnvironment, GrowelabEnv } from 'src/app/common/types/env';
import { environment as defaultEnv } from './environment.default';

export const environment: GrowelabEnv = {
  ...defaultEnv,
  environment: GLEnvironment.QA,
  apiUrl: 'https://qa2.api.engage2learn.org',
  sessionTimeoutMinutes: 10,
  cleverRedirectURI: 'https://qa.growelab.app/clever-auth',
  googleAnalyticsID: 'G-JFXSLH54GQ',
  pusher: {
    key: 'cfe6b01015d18a2c6213',
    cluster: 'us2',
  },
  SENTRY_ORG: 'engage2learn',
  SENTRY_PROJECT: 'growelab-web',
  SENTRY_KEY:
    '519b763d3b944feb82e5349b62abae25d6c7b66c6c8e41db87275eaa884d75dc',
  getstream_appid: '1294596',
  getstream_key: 'pqqcj6xcynxz',
};
