<div class="container pd-catalog-page">
  <app-general-header
    [title]="
      'Manage ' + ((selectedDistrict && selectedDistrict.title) || 'District')
    " />
  <div class="card border-0 p-3 pd-catalog-container">
    <app-settings-nav [districtCode]="districtCode" />
    <div *ngIf="settings; else loading" class="card-body">
      <div *ngIf="isE2L" class="row justify-content-center mb-4">
        <div class="d-flex align-items-center justify-content-center">
          <span class="fw-800 me-2">Scope: </span>
          <ng-select
            #districtSelect
            [clearable]="false"
            [(ngModel)]="districtScopeId"
            (change)="getSettings()"
            style="width: 250px">
            <ng-option [value]="0">GLOBAL - All Districts</ng-option>
            <ng-option *ngFor="let district of districts" [value]="district.id">
              {{ district.title }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-12 col-lg-5 ps-lg-0 pe-lg-5 border-lg-end event-category-container">
          <h4>Event Categorization</h4>
          <ng-container *ngFor="let category of settings.categories">
            <app-pd-settings-category
              [category]="category"
              [districtId]="districtScopeId"
              (categoryUpdated)="getSettings()" />
          </ng-container>

          <div class="d-flex align-items-center mt-4" style="height: 30px">
            <input
              type="text"
              [(ngModel)]="addCategoryValue"
              class="form-control add-category-input"
              (keydown.enter)="addCategory()"
              placeholder="Category Name" />
            <button
              (click)="addCategory()"
              class="btn btn-primary add-category-btn">
              Add Category
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-5 pe-lg-0 ps-lg-5 mt-5 mt-lg-0">
          <h4>Credits</h4>
          <app-pd-settings-credit
            [credits]="settings.credits"
            [districtId]="districtScopeId"
            (creditUpdated)="getSettings()" />
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="w-100" style="background-color: #fff; height: 700px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
</div>
